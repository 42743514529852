<template>
    <div class="pages">
        <!-- 头部 -->
        <div class="page-box-padding">
            <div class="pages-box-top">
                <el-popover placement="bottom" :width="200" trigger="hover">
                    <div>
                        <el-image :src="phoneQrcode" style="width: 100%;">
                        </el-image>
                    </div>
                    <div style="text-align: center;">
                        <div style="font-size: 14px;line-height: 25px;height: 25px;">
                            <span>手机扫码二维码预览效果</span>
                        </div>
                        <div style="font-size: 12px;color: #7f8792;">
                            <span>链接将在10分钟后失效</span>
                        </div>
                    </div>
                    <template #reference>
                        <el-button type="info" plain>扫码预览 <i class="el-icon-s-grid"></i></el-button>
                    </template>
                </el-popover>
                <el-button type="primary" @click="conserveEvent(weipagesid)">退出预览</el-button>
            </div>
        </div>
        <!-- 提示 -->
        <div class="ties">
            <span>此页面仅提供预览,10分钟后失效</span>
        </div>
        <!-- 预览页面 -->
        <div class="pages-box">
            <div class="box-phone">
                <!-- 头部 -->
                <div class="box-top pages-box" style="margin-bottom: 20px;height: 30px;">
                    <div
                        style="width: 10px;height: 10px;background-color: #333333;border-radius: 50%;margin-right: 10px;">
                    </div>
                    <div style="width: 60px;height: 10px;background-color: #333333;border-radius: 20px;"></div>
                </div>
                <!-- 手机内容 -->
                <div class="box-content">
                    <!-- 顶部 -->
                    <div>
                        <img src="@/assets/imgs/weipages/phoneTop.png" alt="" class="statusBar" />
                    </div>
                    <!-- 内容 -->
                    <div>
                        <!-- 手机标题 -->
                        <div>
                            <myphonehead :phoneheaddata="weipagesdata[0]"></myphonehead>
                        </div>
                        <!-- 手机内容 -->
                        <div class="phone-pages-content">
                            <myweipagespromocode :weipagesdata="weipagesdata" :weiformflages="'nosubmit'">
                            </myweipagespromocode>
                        </div>
                    </div>
                </div>
                <!-- 底部 -->
                <div class="box-top pages-box" style="margin-top: 15px;height: 80px;">
                    <div class="box-top pages-box"
                        style="width: 60px;height: 60px;background-color: #333333;border-radius: 50%;">
                        <div
                            style="padding: 5px;background-color: #dbdbdb;width: 40px;height: 40px;border-radius: 50%;">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import myphonehead from "@/components/weipages/subshow/phonehead/phonehead.vue";
import myweipagespromocode from "./weipagespromocode.vue";

import getareajson from '@/assets/json/area.json'
import axios from "axios";

export default {
    name: "vweipagespreview",
    data() {
        return {
            visible: false,
            // 手机内容
            weipagesdata: [],

            // 手机预览二维码
            phoneQrcode: '',

            // 当前预览页-微页id
            weipagesid: '',

            mytoken: localStorage.getItem("token"), //获取存储的token
        }
    },
    methods: {
        // 退出预览-返回到编辑页面
        conserveEvent(data) {
            if (data != '') {
                this.$router.push({
                    path: '/weipagesedit',
                    query: {
                        id: data
                    }
                }).catch((error) => error);
            }
        }
    },
    created() {
        if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
            this.isshow = false;
            this.$router.push("/").catch((error) => error);
        } else {
            // 判断-路由携带-跳转数据
            let routerinfo = this.$route.query;

            if (routerinfo.id) {
                this.weipagesid = routerinfo.id;
                // 获取-预览数据
                axios.post(
                    "/admin/pages/info",
                    {
                        id: routerinfo.id,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    })
                    .then((response) => {
                        if (response.data.code === 0) {
                            let getdata = response.data.result.info
                            let weipagesdata = JSON.parse(getdata.template);

                            // 插入-省市区的options
                            let phonecontentdata = this.$utils.inputdelCityCode(weipagesdata, getareajson, 'input')
                            this.weipagesdata = phonecontentdata
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                // 获取手机预览二维码
                axios.post(
                    "/admin/pages/getQrcode",
                    {
                        page_id: routerinfo.id,
                    },
                    {
                        headers: {
                            Authentication: localStorage.getItem("token"),
                        },
                    })
                    .then((response) => {
                        if (response.data.code === 0) {
                            let getdata = response.data.result
                            this.phoneQrcode = getdata.image
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

        }
    },
    components: {
        myphonehead,
        myweipagespromocode
    },
};
</script>

<style scoped>
.pages {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    background-color: rgba(240, 242, 245, 0.815);
}

.page-box-padding {
    padding: 15px;
    background-color: #ffffff;
}

.pages-box-top {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    background-color: #ffffff;
}

.ties {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: rgb(78, 78, 78);
    color: #ffffff;
    font-size: 14px;
}

.pages-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.box-phone {
    width: 340px;
    height: 680px;
    margin-top: 20px;
    border-radius: 45px;
    border: 4px solid #dbdbdb;
    padding: 20px 15px 15px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.box-top {
    text-align: center;
    align-items: center;
}

.box-content {
    width: 100%;
    height: 100%;
    background-color: rgba(78, 78, 78, 0.075);
    border-radius: 3px;
    border: 1px solid #dbdbdb;
}

.statusBar {
    width: 100%;
    display: block;
}

.phone-pages-content {
    width: 100%;
    height: 494px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>

