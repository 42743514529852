<template>
  <div>
    <div class="phone-head">
      <div class="phone-head-back"><i class="el-icon-arrow-left"></i></div>
      <div class="phone-head-title" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
        <span>{{ phoneheadData.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "phonehead",
  props: ["phoneheaddata"],
  setup(props) {
    const phoneheadData = reactive(props["phoneheaddata"]);

    return {
      phoneheadData,
    };
  },
  methods: {},
  watch: {
      phoneheaddata: {
          handler(newVal) {
              //监听form的数据变化
              this.phoneheadData = newVal;
          },
          deep: true,
      }
    },
};
</script>

<style scoped>
.phone-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  line-height: 40px;
  width: 100%;
  background-color: #ffffff;
}
.phone-head-back {
  width: 40px;
  text-align: center;
}
.phone-head-title {
  width: calc(100% - 80px);
  text-align: center;
}
</style>

